import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { H, P, Grid, Image, BulletSeparator } from '@farewill/ui'
import { BORDER, BREAKPOINT, COLOR, GTR } from '@farewill/ui/tokens'

import { LIVE_PRODUCTS } from 'lib/products/constants'
import { formatPublishDates } from 'lib/time/formatting'
import { BLOG_CATEGORY_PATH } from 'lib/navigation/constants'
import AspectRatioWrapper from 'components/AspectRatioWrapper'
import ArticleAuthorDetails from 'components/ArticleAuthorDetails'
import ArticleCard from 'components/ArticleCard'
import CallToAction from 'components/CallToAction'

import RichContent from '../components/RichContent'

const IMAGE_ASPECT_RATIO = 16 / 9

const StyledBreadcrumb = styled(Link)`
  text-decoration: none;
`
const StyledImageMask = styled(AspectRatioWrapper)`
  border-radius: ${BORDER.RADIUS.S};
  overflow: hidden;
  margin-bottom: ${GTR.L};
`

const BlogArticleContent = ({ data: { article } }) => {
  const {
    cta,
    image,
    author,
    publishedAt,
    description,
    relatedArticles,
    title,
  } = article

  return (
    <Grid container>
      <Grid.Item spanFromM={9} spanFromXL={7}>
        {image && image.file && (
          <StyledImageMask ratio={IMAGE_ASPECT_RATIO}>
            <Image
              alt={image.title}
              path={image.file.url}
              ext={['webp', 'png']}
              formatPath={({ path, width, ext }) =>
                `${path}?w=${width}&h=${Math.round(
                  width / IMAGE_ASPECT_RATIO
                )}&fit=fill&fm=${ext}`
              }
              width={BREAKPOINT.XS}
              widthFromS={BREAKPOINT.S}
              stretch
            />
          </StyledImageMask>
        )}

        <H context color={COLOR.BLACK}>
          <BulletSeparator>
            <StyledBreadcrumb to={BLOG_CATEGORY_PATH}>Blog</StyledBreadcrumb>
            <time dateTime={publishedAt}>
              {formatPublishDates(publishedAt)}
            </time>
          </BulletSeparator>
        </H>
        <H size="XL">{title}</H>

        {author && (
          <ArticleAuthorDetails
            slug={author.slug}
            image={author.image}
            name={author.name}
            title={author.title}
          />
        )}

        {description && (
          <P size="L" margin={[0, 0, 'L']}>
            {description.description}
          </P>
        )}

        {article?.richContent?.raw && (
          <RichContent data={article.richContent} />
        )}

        {cta && (
          <CallToAction
            title={cta.title}
            htmlDescription={cta?.description?.description}
            buttonText={cta.buttonText}
            buttonLink={cta.buttonLink}
            isTelephoneNumber={cta.isTelephoneNumber}
          />
        )}
        {relatedArticles && (
          <>
            <H margin={['XXL', 0, 'M']} size="M">
              More articles
            </H>
            <Grid>
              {relatedArticles.map((relatedArticle) => (
                <Grid.Item key={relatedArticle.id} spanFromL={6}>
                  <ArticleCard
                    type="Blog"
                    title={relatedArticle.title}
                    slug={relatedArticle.slug}
                    image={relatedArticle.image}
                    description={relatedArticle.description.description}
                    publishedAt={relatedArticle.publishedAt}
                  />
                </Grid.Item>
              ))}
            </Grid>
          </>
        )}
      </Grid.Item>
    </Grid>
  )
}

BlogArticleContent.propTypes = {
  data: PropTypes.shape({
    article: PropTypes.shape({
      richContent: PropTypes.shape({
        raw: PropTypes.string,
      }),
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      description: PropTypes.shape({
        description: PropTypes.string.isRequired,
      }),
      metaTitle: PropTypes.string,
      metaDescription: PropTypes.shape({
        metaDescription: PropTypes.string.isRequired,
      }),
      backgroundType: PropTypes.string,
      intro: PropTypes.shape({
        intro: PropTypes.string.isRequired,
      }),
      widget: PropTypes.shape({
        type: PropTypes.string,
      }),
      image: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
        title: PropTypes.string.isRequired,
      }),
      teamMember: PropTypes.shape({
        name: PropTypes.string,
        title: PropTypes.string,
        image: PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }).isRequired,
          title: PropTypes.string.isRequired,
        }),
      }),
      product: PropTypes.oneOf(LIVE_PRODUCTS),
      publishedAt: PropTypes.string.isRequired,
      reviewedAt: PropTypes.string,
      updatedAt: PropTypes.string.isRequired,
      author: PropTypes.shape({
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        image: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
      }).isRequired,
      relatedArticles: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
          type: PropTypes.string,
        })
      ),
      cta: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.shape({
          description: PropTypes.string,
        }).isRequired,
        buttonText: PropTypes.string.isRequired,
        buttonLink: PropTypes.string.isRequired,
        isTelephoneNumber: PropTypes.bool.isRequired,
      }),
      footer: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        cta: PropTypes.shape({
          title: PropTypes.string.isRequired,
          description: PropTypes.shape({
            description: PropTypes.string,
          }),
          link: PropTypes.string,
        }),
        trustmarks: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }).isRequired,
}

export default BlogArticleContent
