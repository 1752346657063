import { COLOR } from '@farewill/ui/tokens'
import React, { useContext } from 'react'
import { Image, Wrapper, Button, H, PhoneSolidIcon } from '@farewill/ui'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import DefaultLayoutContext from 'layouts/context'

import { CALLRAIL_FORWARDING_TELEPHONE_NUMBER } from '../../config'
import formatTelephoneNumber from '../../lib/formatting/telephoneNumber'

const StyledCallToAction = styled(Wrapper)`
  position: relative;
  overflow: hidden;
`

const StyledRelativeWrapper = styled.div`
  position: relative;
`

const StyledTextureOne = styled(Image)`
  pointer-events: none;
  position: absolute;
  transform: rotate(180deg);
  opacity: 0.4;
  left: 0;
  top: 0;
`

const StyledTextureTwo = styled(Image)`
  pointer-events: none;
  position: absolute;
  transform: rotate(-180deg);
  opacity: 0.8;
  right: 0;
`

const StyledDescription = styled(Wrapper)`
  color: ${COLOR.BLACK};
`

const StyledButton = styled(Button.White)`
  ${({ $visible }) =>
    !$visible &&
    css`
      visibility: hidden;
    `}
`

const CallToAction = ({
  title,
  htmlDescription,
  buttonText,
  buttonLink,
  isTelephoneNumber,
}) => {
  const { loadedTracking, trackedPhoneNumber } =
    useContext(DefaultLayoutContext)
  const swappedNumber =
    trackedPhoneNumber || CALLRAIL_FORWARDING_TELEPHONE_NUMBER

  const swappedText = `Call ${formatTelephoneNumber(swappedNumber)}`
  const swappedLink = `tel:${swappedNumber}`

  return (
    <StyledCallToAction
      padding="S"
      paddingFromM="M"
      margin={['M', 0]}
      background={COLOR.ACCENT.PRIMARY}
      borderRadius="M"
    >
      <StyledTextureOne path="textures/pattern-2" width={540} />
      <StyledTextureTwo path="textures/detail-1" width={104} />
      <StyledRelativeWrapper>
        <H margin={[0, 0, 'XS']} size="S" tag="h3">
          {title}
        </H>
        <StyledDescription
          margin={[0, 0, 'M']}
          dangerouslySetInnerHTML={{
            __html: htmlDescription,
          }}
        />
        <StyledButton
          tag="a"
          href={isTelephoneNumber ? swappedLink : buttonLink}
          wide
          icon={isTelephoneNumber && <PhoneSolidIcon />}
          iconOnLeft
          $visible={!isTelephoneNumber || loadedTracking}
        >
          {isTelephoneNumber ? swappedText : buttonText}
        </StyledButton>
      </StyledRelativeWrapper>
    </StyledCallToAction>
  )
}

CallToAction.propTypes = {
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  isTelephoneNumber: PropTypes.bool.isRequired,
  htmlDescription: PropTypes.string.isRequired,
}

export default CallToAction
