import { ROOT_URL } from 'config'

export default {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  url: ROOT_URL,
  name: 'Farewill',
  logo: {
    '@type': 'ImageObject',
    url: `${ROOT_URL}/icons/circle-lockup-logo-icon-500.png`,
  },
  sameAs: 'https://en.wikipedia.org/wiki/Farewill',
}
