import React from 'react'
import PropTypes from 'prop-types'

const JSONLD = ({ data }) => (
  <script
    type="application/ld+json"
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
  />
)

JSONLD.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired, // eslint-disable-line react/forbid-prop-types
}

export default JSONLD
