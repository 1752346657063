import { Image } from '@farewill/ui'
import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { COLOR } from '@farewill/ui/tokens'

import AspectRatioWrapper from 'components/AspectRatioWrapper'

const StyledCircle = styled(AspectRatioWrapper)`
  border-radius: 50%;
  background-color: ${COLOR.ACCENT.PRIMARY};
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;

  ${({ stretch, size }) =>
    stretch
      ? css`
          img {
            max-width: 100%;
          }
        `
      : `
    && {
      max-width: ${size}px;
      padding-bottom: ${size}px;
    }
  `}
`

const Avatar = ({ className, imageSrc, size, alt, stretch }) => (
  <StyledCircle ratio={1} stretch={stretch} size={size} className={className}>
    {imageSrc ? (
      <Image
        path={imageSrc}
        formatPath={({ path, width }) => `${path}?w=${width}`}
        width={size}
        alt={alt}
        stretch={stretch}
      />
    ) : (
      <Image
        path="com-assets/placeholder-avatar"
        width={size}
        stretch={stretch}
      />
    )}
  </StyledCircle>
)

Avatar.propTypes = {
  className: PropTypes.string,
  imageSrc: PropTypes.string,
  alt: PropTypes.string,
  size: PropTypes.number.isRequired,
  stretch: PropTypes.bool,
}

Avatar.defaultProps = {
  className: null,
  imageSrc: null,
  alt: '',
  stretch: false,
}

export default Avatar
