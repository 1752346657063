import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Wrapper, BulletSeparator } from '@farewill/ui'
import { GTR, FONT } from '@farewill/ui/tokens'
import { screenMax } from '@farewill/ui/helpers/responsive'

import PATHS from 'paths'
import Avatar from 'components/Avatar'

const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ $size }) => $size + 10}px 1fr;
  grid-gap: ${GTR.XS};
  align-items: center;
`

const StyledAvatar = styled(Avatar)`
  margin-right: ${GTR.XS};
  vertical-align: middle;

  ${screenMax.l`
    ${({ size }) => `
      width: ${size}px;
      padding-bottom: ${size}px;

      img {
        width: ${size}px;
      }
    `}

  `}
`

const StyledText = styled(BulletSeparator)`
  display: inline;
  vertical-align: middle;
`

const StyledLink = styled(Link)`
  font-size: ${FONT.SIZE.S};
  text-decoration: none;

  &:hover {
    ${StyledText} {
      text-decoration: underline;
    }
  }
`

const StyledAuthorName = styled.span`
  font-weight: ${FONT.WEIGHT.BOLD};
`

const ArticleAuthorDetails = ({ slug, image, name, title, avatarSize }) => {
  const Details = (
    <StyledWrapper $size={avatarSize === 'small' ? 30 : 50}>
      <StyledAvatar
        imageSrc={image && image.file && image.file.url}
        size={avatarSize === 'small' ? 30 : 50}
      />
      <StyledText>
        <StyledAuthorName>{name}</StyledAuthorName>
        {title && <span>{title}</span>}
      </StyledText>
    </StyledWrapper>
  )

  return (
    <Wrapper margin={[0, 0, 'L']} marginFromM={[0, 0, 40]}>
      {slug ? (
        <StyledLink to={`${PATHS.GENERAL.AUTHORS}/${slug}`}>
          {Details}
        </StyledLink>
      ) : (
        Details
      )}
    </Wrapper>
  )
}

ArticleAuthorDetails.propTypes = {
  slug: PropTypes.string,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  avatarSize: PropTypes.oneOf(['small', 'large']),
  image: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string,
  }).isRequired,
}

ArticleAuthorDetails.defaultProps = {
  slug: '',
  avatarSize: 'small',
}

export default ArticleAuthorDetails
