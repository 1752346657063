import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { H, P, Image, Grid } from '@farewill/ui'
import { COLOR, BORDER, GTR, BREAKPOINT } from '@farewill/ui/tokens'
import { screenMax } from '@farewill/ui/helpers/responsive'

import AspectRatioWrapper from 'components/AspectRatioWrapper'
import { formatPublishDates } from 'lib/time/formatting'
import { getArticlePath } from 'lib/contentful/articles'
import { ARTICLE_TYPES } from 'lib/contentful//constants'

const IMAGE_ASPECT_RATIO = 16 / 9
const BORDER_WIDTH = 1

const StyledImageMask = styled(AspectRatioWrapper)`
  ${({ $isBasic }) =>
    !$isBasic && `border: solid ${BORDER_WIDTH}px ${COLOR.GREY.LIGHT}`};
  border-radius: ${BORDER.RADIUS.S};
  background: ${COLOR.WHITE};
  overflow: hidden;
  margin-bottom: ${GTR.M};

  img {
    max-width: 100%;
  }
`

const StyledTimestamp = styled(H)`
  margin-top: 0;
`

const StyledTextWrapper = styled(Grid.Item)`
  align-self: center;
`

const StyledArticleTitle = styled(H)`
  ${({ $isBasic }) => $isBasic && `padding-top: ${GTR.L};`}
`

const StyledArticleGrid = styled(Grid)`
  ${({ $isBasic }) =>
    $isBasic &&
    `
    height: 100%;
  `}
`

const StyledArticleCard = styled(Link)`
  display: block;
  text-decoration: none;

  ${({ $isBasic }) =>
    $isBasic &&
    `
    border-top: solid ${BORDER_WIDTH}px ${COLOR.GREY.MEDIUM};
    margin-bottom: ${GTR.M};
    height: 100%;
    `}

  &:hover {
    text-decoration: none;

    ${StyledArticleTitle} {
      text-decoration: underline;
    }
  }

  ${({ $large, $isBasic }) =>
    $large &&
    !$isBasic &&
    css`
      border-radius: ${BORDER.RADIUS.S};
      border: solid 1px ${COLOR.GREY.LIGHT};

      ${screenMax.s`
        background-color: ${COLOR.BACKGROUND.FOG};
      `};
    `}

  ${({ $large }) =>
    $large &&
    css`
      background-color: ${COLOR.WHITE};

      ${StyledImageMask} {
        border-style: none;
        position: relative;
      }

      ${screenMax.s`
        border: none;
      `};
    `}
`

const ArticleCard = ({
  type,
  title,
  slug,
  image,
  description,
  publishedAt,
  large,
  isBasic,
}) => (
  <StyledArticleCard
    to={getArticlePath(slug, type)}
    $large={large}
    $isBasic={isBasic}
  >
    <StyledArticleGrid
      paddingFromM={large && !isBasic && 'L'}
      paddingFromXL={large && !isBasic && ['L', 'L', 'L', 'XL']}
      $isBasic={isBasic}
    >
      {image && (
        <Grid.Item
          span={12}
          spanFromM={large ? 6 : 12}
          startColumnFromM={large ? 7 : 0}
          startRow={isBasic ? 2 : 1}
          style={!large ? { 'align-self': 'end' } : {}}
        >
          <StyledImageMask ratio={IMAGE_ASPECT_RATIO} $isBasic={isBasic}>
            {image && image.file && (
              <Image
                path={image.file.url}
                formatPath={({ width, path, ext }) =>
                  `${path}?w=${width}&h=${Math.round(
                    width / IMAGE_ASPECT_RATIO + 2 * BORDER_WIDTH
                  )}&fm=${ext}&fit=${
                    type === ARTICLE_TYPES.HELP ? 'pad' : 'fill'
                  }`
                }
                width={BREAKPOINT.XS}
                widthFromS={BREAKPOINT.S}
                widthFromM={large ? 800 : 660}
                widthFromL={large ? 1060 : 540}
                stretch
              />
            )}
          </StyledImageMask>
        </Grid.Item>
      )}

      <StyledTextWrapper
        span={12}
        spanFromM={large ? 6 : 12}
        startRow={isBasic ? 1 : 2}
        startRowFromM={(isBasic && !large) || (!isBasic && large) ? 1 : 2}
        style={!large ? { 'align-self': 'start' } : {}}
      >
        <StyledArticleTitle size="S" $isBasic={isBasic}>
          {title}
        </StyledArticleTitle>
        <P>{description}</P>
        {publishedAt && (
          <StyledTimestamp context>
            <time dateTime={publishedAt}>
              {formatPublishDates(publishedAt)}
            </time>
          </StyledTimestamp>
        )}
      </StyledTextWrapper>
    </StyledArticleGrid>
  </StyledArticleCard>
)

ArticleCard.propTypes = {
  type: PropTypes.oneOf(['Blog', 'Help']).isRequired,
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
    title: PropTypes.string,
  }),
  publishedAt: PropTypes.string.isRequired,
  large: PropTypes.bool,
  isBasic: PropTypes.bool,
}

ArticleCard.defaultProps = {
  large: false,
  isBasic: false,
  image: undefined,
}

export default ArticleCard
