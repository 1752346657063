import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import qs from 'qs'

import DefaultLayout from 'layouts/DefaultLayout'
import JSONLD from 'components/JSONLD'
import { getArticlePath } from 'lib/contentful/articles'
import { ARTICLE_TYPES } from 'lib/contentful/constants'
import { SHARE_IMAGE_DIMENSIONS } from 'lib/sharing/constants'
import FAREWILL_JSONLD from 'lib/json-ld/farewill'

import { ROOT_URL } from 'config'

import BlogArticleContent from './BlogArticleContent'

const SHARE_IMAGE_PARAMS = {
  w: SHARE_IMAGE_DIMENSIONS.WIDTH,
  h: SHARE_IMAGE_DIMENSIONS.HEIGHT,
  fit: 'fill',
}

const BlogArticle = ({ data: { article } }) => {
  const url = `${ROOT_URL}${getArticlePath(article.slug, ARTICLE_TYPES.BLOG)}`
  const shareImageUrl =
    article.image &&
    article.image.file &&
    `https:${article.image.file.url}?${qs.stringify(SHARE_IMAGE_PARAMS)}`

  return (
    <>
      <JSONLD
        data={{
          '@context': 'http://schema.org',
          '@type': 'BlogPosting',
          headline: article.title,
          description: article?.description?.description,
          image: article.image &&
            article.image.file && {
              '@type': 'imageObject',
              url: shareImageUrl,
              width: SHARE_IMAGE_DIMENSIONS.WIDTH,
              height: SHARE_IMAGE_DIMENSIONS.HEIGHT,
            },
          url,
          mainEntityOfPage: url,
          publisher: FAREWILL_JSONLD,
          author: article.author && {
            '@type': 'Person',
            name: article.author.name,
            url: `${ROOT_URL}/authors/${article.author.slug}`,
          },
          dateCreated: article.publishedAt,
          datePublished: article.publishedAt,
          dateModified: article.updatedAt,
        }}
      />
      <DefaultLayout
        title={article.metaTitle || article.title}
        description={
          article?.metaDescription?.metaDescription ||
          article?.description?.description
        }
        shareImage={shareImageUrl}
        meta={
          article.author
            ? [
                {
                  property: 'og:author',
                  content: article.author.name,
                },
              ]
            : []
        }
      >
        <BlogArticleContent data={{ article }} />
      </DefaultLayout>
    </>
  )
}

BlogArticle.propTypes = {
  data: PropTypes.shape({
    article: PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      description: PropTypes.shape({
        description: PropTypes.string.isRequired,
      }),
      metaTitle: PropTypes.string,
      metaDescription: PropTypes.shape({
        metaDescription: PropTypes.string.isRequired,
      }),
      image: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
        title: PropTypes.string.isRequired,
      }),
      publishedAt: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired,
      author: PropTypes.shape({
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        image: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
      }).isRequired,
      relatedArticles: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
          description: PropTypes.shape({
            description: PropTypes.string.isRequired,
          }).isRequired,
          publishedAt: PropTypes.string.isRequired,
          image: PropTypes.shape({
            file: PropTypes.shape({
              url: PropTypes.string.isRequired,
            }).isRequired,
            title: PropTypes.string.isRequired,
          }),
        })
      ),
      cta: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.shape({
          description: PropTypes.string,
        }).isRequired,
        buttonText: PropTypes.string.isRequired,
        buttonLink: PropTypes.string.isRequired,
        isTelephoneNumber: PropTypes.bool,
      }),
    }).isRequired,
  }).isRequired,
}

export const query = graphql`
  query BlogArticle($slug: String!) {
    article: contentfulArticle(slug: { eq: $slug }) {
      title
      slug
      description {
        description
      }
      metaTitle
      metaDescription {
        metaDescription
      }
      image {
        file {
          url
        }
        title
      }
      publishedAt
      updatedAt
      richContent {
        raw
        references {
          ... on ContentfulCallout {
            ...Callout
          }
          ... on ContentfulRichImage {
            ...RichImage
          }
          ... on ContentfulCallToAction {
            ...CallToAction
          }
          ... on ContentfulStepsSection {
            ...StepsSection
          }
          ... on ContentfulModule {
            ...Module
          }
          ... on ContentfulAsset {
            ...Asset
          }
        }
      }
      author {
        name
        slug
        title
        image {
          file {
            url
          }
          title
        }
      }
      relatedArticles {
        id
        title
        slug
        description {
          description
        }
        image {
          file {
            url
          }
          title
        }
        publishedAt
      }
      cta {
        ...CallToActionFields
      }
    }
  }
`

export default BlogArticle
