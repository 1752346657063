import PATHS from 'paths'
import { ARTICLE_TYPES } from './constants'

type ArticlesGroupedByType = Record<
  ARTICLE_TYPES,
  GatsbyTypes.ContentfulArticle[]
>

export const groupByType = (
  articles: GatsbyTypes.ContentfulArticle[]
): ArticlesGroupedByType => {
  const { HELP, BLOG } = ARTICLE_TYPES
  return articles.reduce<ArticlesGroupedByType>(
    (memo, article) => {
      switch (article.type) {
        case HELP:
          return {
            ...memo,
            [HELP]: memo[HELP].concat(article),
          }
        case BLOG:
          return { ...memo, [BLOG]: memo[BLOG].concat(article) }
        default:
          return memo
      }
    },
    { [HELP]: [], [BLOG]: [] }
  )
}

export const addGuideContextDataToArticles = (
  helpArticles: GatsbyTypes.ContentfulArticle[],
  guides: GatsbyTypes.ContentfulGuide[]
): (GatsbyTypes.ContentfulArticle & {
  guideSlug: GatsbyTypes.Maybe<string>
  nextArticleInGuideSlug: GatsbyTypes.Maybe<string>
})[] =>
  helpArticles.map((article) => {
    const parentGuide = guides.find((guide) =>
      guide?.articles?.map((a) => a?.slug).includes(article.slug)
    )

    let nextArticleInGuideSlug
    if (parentGuide && parentGuide.articles) {
      const articleIndex = parentGuide.articles.findIndex(
        (a) => a?.slug === article.slug
      )
      if (articleIndex < parentGuide.articles.length - 1) {
        nextArticleInGuideSlug = parentGuide.articles[articleIndex + 1]?.slug
      }
    }

    return {
      ...article,
      guideSlug: parentGuide ? parentGuide.slug : undefined,
      nextArticleInGuideSlug,
    }
  })

export const getAuthorSlugsFromArticles = (
  articles: GatsbyTypes.ContentfulArticle[]
): string[] => {
  const authorSlugs = articles
    .filter((article) => !!article.author)
    .map((article) => article?.author?.slug)
    .filter((slug): slug is string => !!slug)

  return [...new Set(authorSlugs)]
}

export const getArticlePath = (slug: string, type: string): string => {
  const prefix =
    type === ARTICLE_TYPES.BLOG ? PATHS.GENERAL.BLOG : PATHS.GENERAL.ARTICLES
  return `${prefix}/${slug}`
}
